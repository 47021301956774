<template>
  <div class="body">
    <van-cell
      title="切换就诊人"
      icon="manager-o"
      class="van-cell-chooseItem"
      is-link
      style="text-align: left"
      @click="openChooise()"
    >
      <template #default>
        <span style="color: #999">{{ chooseItem.name }}</span>
      </template>
    </van-cell>
    <van-popup v-model="showPopup" position="bottom" round closeable>
      <div class="yyqr-choose-jzk-over">
        <div class="yyqr-choose-title">选择就诊人</div>
      </div>
      <div
        class="yyqr-choose-jzk"
        v-for="(item, index) in list"
        :key="index"
        @click="chooseJZPerson(item)"
      >
        <span v-if="item.id == personId" class="checked">
          <img src="@/assets/img/checked.png" alt="" />
          {{ item.name + "(" + item.idNumber + ")" }}</span
        >
        <span v-else>{{ item.name + "(" + item.idNumber + ")" }}</span>
      </div>
      <div class="yyqr-choose-nodata" v-show="list.length == 0">
        <img
          src="../../assets/img/appoint/zanwujiuzhenren.png"
          width="100%"
          height="100%"
          alt=""
        />
      </div>
      <div class="yyqr-choose-add" @click="addPatient">
        <van-icon name="plus" />添加就诊人
      </div>
    </van-popup>
    <div>
      <van-form @submit="onSubmit" colon>
        <div class="checked-info">
          <div class="checked-info-item">
            <div class="checked-info-title">
              &nbsp;&nbsp;近两周是否出现以下症状（多选）
            </div>
            <div class="check-box">
              <van-radio-group
                v-model="isCheck"
                direction="horizontal"
                :disabled="disabled"
              >
                <van-radio
                  :name="false"
                  icon-size="18px"
                  checked-color="#00D48A"
                >
                  <template #icon="props">
                    <i
                      :class="props.checked ? 'activeIcon' : 'inactiveIcon'"
                    ></i>
                  </template>
                  否
                </van-radio>
                <van-radio
                  :name="true"
                  icon-size="18px"
                  checked-color="#FF0000"
                >
                  <template #icon="props">
                    <i
                      :class="props.checked ? 'activeIcon1' : 'inactiveIcon'"
                    ></i> </template
                  >是</van-radio
                >
              </van-radio-group>
            </div>
            <div class="checked-info-item-checked">
              <van-checkbox
                checked-color="#00D48A"
                icon-size="16px"
                v-model="isFever"
                label-disabled
                :disabled="disabled2"
              >
                <span style="color: #ff0000"
                  >发热，共
                  <input
                    type="number"
                    :readonly="disabled2"
                    class="feverDays"
                    v-model="feverDays"
                  />
                  天，最高
                  <input
                    type="number"
                    class="feverTemperature"
                    min="0.00"
                    step="0.01"
                    :readonly="disabled2"
                    v-model="feverTemperature"
                  />℃</span
                >
              </van-checkbox>
              <div class="aa">
                <van-checkbox
                  class="aa1"
                  checked-color="#00D48A"
                  icon-size="16px"
                  v-model="isWeakness"
                  :disabled="disabled2"
                  >乏力</van-checkbox
                >
                <van-checkbox
                  class="aa1"
                  checked-color="#00D48A"
                  icon-size="16px"
                  v-model="isCough"
                  :disabled="disabled2"
                  >干咳</van-checkbox
                >
                <van-checkbox
                  class="aa1"
                  checked-color="#00D48A"
                  icon-size="16px"
                  v-model="isRhinobyon"
                  :disabled="disabled2"
                  >鼻塞</van-checkbox
                >
                <van-checkbox
                  class="aa1"
                  checked-color="#00D48A"
                  icon-size="16px"
                  v-model="isRunnyNose"
                  :disabled="disabled2"
                  >流涕</van-checkbox
                >
                <van-checkbox
                  class="aa1"
                  checked-color="#00D48A"
                  icon-size="16px"
                  v-model="isSoreThroat"
                  :disabled="disabled2"
                  >咽痛</van-checkbox
                >
                <van-checkbox
                  class="aa1"
                  checked-color="#00D48A"
                  icon-size="16px"
                  v-model="isDiarrhea"
                  :disabled="disabled2"
                  >腹泻</van-checkbox
                >
                <van-checkbox
                  class="aa1"
                  checked-color="#00D48A"
                  icon-size="16px"
                  v-model="isConjunctivitis"
                  :disabled="disabled2"
                  >结膜炎</van-checkbox
                >
                <van-checkbox
                  class="aa1"
                  checked-color="#00D48A"
                  icon-size="16px"
                  v-model="isMyalgia"
                  :disabled="disabled2"
                  >肌痛</van-checkbox
                >
                <van-checkbox
                  class="aa1"
                  checked-color="#00D48A"
                  icon-size="16px"
                  v-model="isFeelFade"
                  :disabled="disabled2"
                  >嗅觉味觉减退</van-checkbox
                >
              </div>
            </div>
          </div>

          <div class="checked-info-title">&nbsp;&nbsp;流行病学史</div>
          <div class="bb">
            <div class="checked-info-item">
              <div class="checked-info-item-title">
                1、发病前7天内有病例或无症状感染者报告楼栋/单元的旅行史或居住史?
              </div>
              <div class="checked-info-item-radio">
                <van-radio-group
                  v-model="tourismHistory"
                  direction="horizontal"
                  :disabled="disabled"
                >
                  <van-radio
                    :name="false"
                    icon-size="18px"
                    checked-color="#00D48A"
                  >
                    <template #icon="props">
                      <i
                        :class="props.checked ? 'activeIcon' : 'inactiveIcon'"
                      ></i>
                    </template>
                    否
                  </van-radio>
                  <van-radio
                    :name="true"
                    icon-size="18px"
                    checked-color="#FF0000"
                  >
                    <template #icon="props">
                      <i
                        :class="props.checked ? 'activeIcon1' : 'inactiveIcon'"
                      ></i> </template
                    >是</van-radio
                  >
                </van-radio-group>
              </div>
            </div>
            <div class="checked-info-item">
              <div class="checked-info-item-title">
                2、发病前7天内与新型冠状病毒感染的患者或无症状感染者有接触史?
              </div>
              <div class="checked-info-item-radio">
                <van-radio-group
                  v-model="contactHistory"
                  direction="horizontal"
                  :disabled="disabled"
                >
                  <van-radio
                    :name="false"
                    icon-size="18px"
                    checked-color="#00D48A"
                  >
                    <template #icon="props">
                      <i
                        :class="props.checked ? 'activeIcon' : 'inactiveIcon'"
                      ></i>
                    </template>
                    否
                  </van-radio>
                  <van-radio
                    :name="true"
                    icon-size="18px"
                    checked-color="#FF0000"
                  >
                    <template #icon="props">
                      <i
                        :class="props.checked ? 'activeIcon1' : 'inactiveIcon'"
                      ></i> </template
                    >是</van-radio
                  >
                </van-radio-group>
              </div>
            </div>
            <!-- <div class="checked-info-item">
              <div class="checked-info-item-title">
                3、发病前14天内曾接触过来自有病例或无症状感染者报告的社区的发热和/或有呼吸道症状的患者?
              </div>
              <div class="checked-info-item-radio">
                <van-radio-group
                  v-model="contactFeverPatients"
                  direction="horizontal"
                  :disabled="disabled"
                >
                  <van-radio
                    :name="false"
                    icon-size="18px"
                    checked-color="#00D48A"
                  >
                    <template #icon="props">
                      <i
                        :class="props.checked ? 'activeIcon' : 'inactiveIcon'"
                      ></i>
                    </template>
                    否
                  </van-radio>
                  <van-radio
                    :name="true"
                    icon-size="18px"
                    checked-color="#FF0000"
                  >
                    <template #icon="props">
                      <i
                        :class="props.checked ? 'activeIcon1' : 'inactiveIcon'"
                      ></i> </template
                    >是</van-radio
                  >
                </van-radio-group>
              </div>
            </div> -->
            <div class="checked-info-item">
              <div class="checked-info-item-title1">
                3、聚集性发病(1周内在小范围如家庭、办公室、学校班级等场所,出现2例及以上发热和/或呼吸道症状的病例)
              </div>
              <div class="checked-info-item-radio">
                <van-radio-group
                  v-model="aggregationOnset"
                  direction="horizontal"
                  :disabled="disabled"
                >
                  <van-radio
                    :name="false"
                    icon-size="18px"
                    checked-color="#00D48A"
                  >
                    <template #icon="props">
                      <i
                        :class="props.checked ? 'activeIcon' : 'inactiveIcon'"
                      ></i>
                    </template>
                    否
                  </van-radio>
                  <van-radio
                    :name="true"
                    icon-size="18px"
                    checked-color="#FF0000"
                  >
                    <template #icon="props">
                      <i
                        :class="props.checked ? 'activeIcon1' : 'inactiveIcon'"
                      ></i> </template
                    >是</van-radio
                  >
                </van-radio-group>
              </div>
            </div>
            <div class="checked-info-item">
              <div class="checked-info-item-title2">
                4、发病前7天内有国内高风险地区及其关联地区或各地区政府发布的管控区域内旅行史或居住史。
              </div>
              <div class="checked-info-item-radio">
                <van-radio-group
                  v-model="riskAreaHistory"
                  direction="horizontal"
                  :disabled="disabled"
                >
                  <van-radio
                    :name="false"
                    icon-size="18px"
                    checked-color="#00D48A"
                  >
                    <template #icon="props">
                      <i
                        :class="props.checked ? 'activeIcon' : 'inactiveIcon'"
                      ></i>
                    </template>
                    否
                  </van-radio>
                  <van-radio
                    :name="true"
                    icon-size="18px"
                    checked-color="#FF0000"
                  >
                    <template #icon="props">
                      <i
                        :class="props.checked ? 'activeIcon1' : 'inactiveIcon'"
                      ></i> </template
                    >是</van-radio
                  >
                </van-radio-group>
              </div>
            </div>
            <!-- <div class="checked-info-item">
              <div class="checked-info-item-title2">
                6、您是否为涉及进口冷链物品的口岸一线海关检验检疫人员?
              </div>
              <div class="checked-info-item-radio">
                <van-radio-group
                  v-model="customsInspection"
                  direction="horizontal"
                  :disabled="disabled"
                >
                  <van-radio
                    :name="false"
                    icon-size="18px"
                    checked-color="#00D48A"
                  >
                    <template #icon="props">
                      <i
                        :class="props.checked ? 'activeIcon' : 'inactiveIcon'"
                      ></i>
                    </template>
                    否
                  </van-radio>
                  <van-radio
                    :name="true"
                    icon-size="18px"
                    checked-color="#FF0000"
                  >
                    <template #icon="props">
                      <i
                        :class="props.checked ? 'activeIcon1' : 'inactiveIcon'"
                      ></i> </template
                    >是</van-radio
                  >
                </van-radio-group>
              </div>
            </div>
            <div class="checked-info-item">
              <div class="checked-info-item-title2">
                7、您是否为口岸装卸、搬运、运输,国际和国内交通运输业相关人员?
              </div>
              <div class="checked-info-item-radio">
                <van-radio-group
                  v-model="transportationPersonnel"
                  direction="horizontal"
                  :disabled="disabled"
                >
                  <van-radio
                    :name="false"
                    icon-size="18px"
                    checked-color="#00D48A"
                  >
                    <template #icon="props">
                      <i
                        :class="props.checked ? 'activeIcon' : 'inactiveIcon'"
                      ></i>
                    </template>
                    否
                  </van-radio>
                  <van-radio
                    :name="true"
                    icon-size="18px"
                    checked-color="#FF0000"
                  >
                    <template #icon="props">
                      <i
                        :class="props.checked ? 'activeIcon1' : 'inactiveIcon'"
                      ></i> </template
                    >是</van-radio
                  >
                </van-radio-group>
              </div>
            </div>
            <div class="checked-info-item">
              <div class="checked-info-item-title2">
                8、您是否为往返相关、国外人员、冷链工人、隔离酒店工作人员?
              </div>
              <div class="checked-info-item-radio">
                <van-radio-group
                  v-model="workingPersonnel"
                  direction="horizontal"
                  :disabled="disabled"
                >
                  <van-radio
                    :name="false"
                    icon-size="18px"
                    checked-color="#00D48A"
                  >
                    <template #icon="props">
                      <i
                        :class="props.checked ? 'activeIcon' : 'inactiveIcon'"
                      ></i>
                    </template>
                    否
                  </van-radio>
                  <van-radio
                    :name="true"
                    icon-size="18px"
                    checked-color="#FF0000"
                  >
                    <template #icon="props">
                      <i
                        :class="props.checked ? 'activeIcon1' : 'inactiveIcon'"
                      ></i> </template
                    >是</van-radio
                  >
                </van-radio-group>
              </div>
            </div>
            <div class="checked-info-item">
              <div class="checked-info-item-title2">
                9、您是否刚完成隔离（隔离14天）,解除隔离后未满7天?
              </div>
              <div class="checked-info-item-radio">
                <van-radio-group
                  v-model="isolatePersonnel"
                  direction="horizontal"
                  :disabled="disabled"
                >
                  <van-radio
                    :name="false"
                    icon-size="18px"
                    checked-color="#00D48A"
                  >
                    <template #icon="props">
                      <i
                        :class="props.checked ? 'activeIcon' : 'inactiveIcon'"
                      ></i>
                    </template>
                    否
                  </van-radio>
                  <van-radio
                    :name="true"
                    icon-size="18px"
                    checked-color="#FF0000"
                  >
                    <template #icon="props">
                      <i
                        :class="props.checked ? 'activeIcon1' : 'inactiveIcon'"
                      ></i> </template
                    >是</van-radio
                  >
                </van-radio-group>
              </div>
            </div> -->
          </div>

          <div class="checked-info-title">&nbsp;&nbsp;承诺书</div>
          <div class="promise-item">
            <div class="www">
              本人郑重承诺，以上所有信息真实有效，愿意承担不实信息所带来的一切后果。在院期间正确佩戴口罩，自觉保持个人清洁卫生、勤洗手、注意咳嗽礼仪。主动配合安保人员管理，认真执行医院疫情防控相关管理规定。
            </div>
            <van-checkbox
              class="www1"
              checked-color="#00D48A"
              icon-size="16px"
              v-model="isAgree"
              :disabled="disabled"
            >
              <template #icon="props">
                <i :class="props.checked ? 'activeIcon' : 'inactiveIcon'"></i>
              </template>
              本人完全了解上述内容并承诺
            </van-checkbox>
            <van-cell-group class="qqq">
              <van-cell>
                <van-field
                  v-model="name"
                  label="承诺人"
                  label-width="2rem"
                  input-align="right"
                  placeholder="请填写真实姓名"
                  :disabled="disabled"
                />
              </van-cell>
              <van-cell>
                <van-field
                  v-model="idNumber"
                  label="身份证号"
                  label-width="2rem"
                  input-align="right"
                  placeholder="请输入您的身份证号"
                  :disabled="disabled"
                />
              </van-cell>
              <van-cell>
                <van-field
                  v-model="phone"
                  type="tel"
                  label="联系电话"
                  label-width="2rem"
                  input-align="right"
                  placeholder="请输入您的联系电话"
                  :disabled="disabled"
                />
              </van-cell>
            </van-cell-group>
          </div>
        </div>
        <div style="margin: 16px">
          <van-button
            block
            type="info"
            class="submit-btn"
            native-type="submit"
            v-if="isAgree == true"
            >提交</van-button
          >
          <van-button
            block
            type="info"
            disabled
            native-type="submit"
            class="submit-btn-disabled"
            v-if="isAgree == false"
            >提交</van-button
          >
        </div>
      </van-form>
    </div>
    <van-dialog
      v-model="showDialog"
      :show-cancel-button="false"
      :show-confirm-button="false"
      @confirm="handleConfirm"
    >
      <template #title>
        <h3>提示</h3>
      </template>
      <template #default>
        <p class="dialog-message">{{ tip_notice }}</p>
        <van-button type="primary" class="confirm-btn" @click="handleConfirm"
          >确认</van-button
        >
      </template>
    </van-dialog>
    <van-dialog
      class="dialog"
      v-model="overflowShow1"
      title="温馨提示"
      :show-cancel-button="false"
      :show-confirm-button="false"
    >
      <template #title>
        <h3 class="dialog-title">温馨提示</h3>
      </template>
      <p>暂无就诊人信息，请添加就诊人</p>
      <van-button class="cancle-btn" to="/treatmentServices">取消</van-button>
      <van-button type="primary" class="confirm-btn" @click="addPatient"
        >确认</van-button
      >
    </van-dialog>
    <van-dialog
      class="dialog1"
      v-model="overflowShow2"
      :show-cancel-button="false"
      :show-confirm-button="false"
    >
      <template #title>
        <h3 class="dialog-title">服务须知</h3>
      </template>
      <div class="dialog-message">
        <p>
          1.该服务主要是用于社会人员核酸检测。有流行病学史或伴有发热及呼吸道症状的患者请勿使用线上医疗服
          务，务必到医院发热门诊就诊。
        </p>
        <p>2.下单取样流程：</p>
        <p>手机下单缴费(暂不支持医保)后，直接前往指定核酸采样点取样。</p>
        <p>
          3.结果查询：电子报告可在“检验报告”中查看，纸质报告可持身份证在急诊楼东侧检验报告自助机打印获取。
        </p>
        <p>
          4.发票获取：缴费完成后，电子发票可在“个人中心-电子发票”中查看下载，纸质发票可前往医院自助机进行打印。
        </p>
        <p>
          5.退费：缴费成功后如有特殊原因不能前往检验，请在“诊疗服务-核酸缴费”中申请退款。
        </p>
      </div>
      <van-button
        type="primary"
        class="confirm-btn"
        @click="overflowShow2 = false"
        >确认</van-button
      >
    </van-dialog>
  </div>
</template>

<script>
import { Dialog, Notify } from "vant";

export default {
  name: "enterPromise.vue",
  data() {
    return {
      showDialog: false,
      tip_notice: "",
      changeStatus: "", //支付状态
      OpenId: "", //openId
      name: "",
      idNumber: "",
      phone: "",
      inpDept: "",
      inpNo: "",
      workUnit: "",
      address: "",
      personType: "0", //患者还是陪护
      tourismHistory: "", //发病前14天内有病例或无症状感染者报告社区的旅行史或居住史?
      contactHistory: "", //发病前14天内与新型冠状病毒感染的患者或无症状感染者有接触史?
      contactFeverPatients: "", //发病前14天内曾接触过来自有病例或无症状感染者报告的社区的发热和/或有呼吸道症状的患者?
      aggregationOnset: "", //聚集性发病（2周内在小范围如家庭、办公室、学校班级等场所,出现2例及以上发热和/或呼吸道症状的病例）
      riskAreaHistory: "", // 发病前14天内有国内中高风险地区及其关联地区或各地区政府发布的管控区域内旅行史或居住史。
      customsInspection: "", // 涉及进口冷链物品的口岸一线海关检验检疫人员
      transportationPersonnel: "", // 口岸装卸、搬运、运输，国际和国内交通运输业相关人员
      workingPersonnel: "", // 往返相关、国外人员、冷链工人、隔离酒店工作人员
      isolatePersonnel: "", // 完成隔离（隔离14天），解除隔离后未满7天
      // isFever: this.feverDays!=null?true:false, //发热
      isFever: false, //发热
      feverDays: "", //发热天数
      feverTemperature: "", //发热温度
      isWeakness: "", //乏力
      isCough: "", //干咳
      isRhinobyon: "", //鼻塞
      isRunnyNose: "", //流涕
      isSoreThroat: "", //咽痛
      isDiarrhea: "", //腹泻
      isFeelFade: "", //嗅觉味觉减退
      isConjunctivitis: "", //结膜炎
      isMyalgia: "", //肌痛
      isAgree: true, //同意承诺书上的内容
      // 填写日期
      date: "",
      //其他
      showCalendar: false,
      redQrCode: false, //是否为红二维码
      disabled: false,
      isCheck: "", // 第一条问卷的多选项

      overflowShow1: false,
      overflowShow2: true,
      personId: "",
      chooseItem: "",
      list: [],
      showPopup: false, // 弹出层
    };
  },
  computed: {
    // 第一条问卷的多选项的禁用
    disabled2: function () {
      if (this.isCheck === "") {
        return true;
      }
      if (this.disabled) {
        return true;
      }
      if (this.isCheck == true) {
        return false;
      } else {
        return true;
      }
    },
  },
  watch: {
    //发热监听天和温度
    feverDays: function (val) {
      if (val == null && this.feverTemperature == null) {
        this.isFever = false;
        return;
      }
      if (val == "" && this.feverTemperature == "") {
        this.isFever = false;
        return;
      }
      this.isFever = true; // 单选框的勾选
    },
    feverTemperature: function (val) {
      if (val == null && this.feverDays == null) {
        this.isFever = false;
        return;
      }
      if (val == "" && this.feverDays == "") {
        this.isFever = false;
        return;
      }
      this.isFever = true;
    },
  },
  methods: {
    /**
     * 判断是否有就诊人
     */
    isHasPatient() {
      if (!this.chooseItem) {
        this.overflowShow1 = true;
        return false;
      }
      return true;
    },
    // 点击就诊人
    openChooise() {
      this.showPopup = true;
    },
    // 添加就诊人
    addPatient() {
      sessionStorage.setItem("backRoute", this.$route.fullPath);
      this.$router.push("/addCardOne");
    },
    /**
     * 选择就诊人
     */
    chooseJZPerson(item) {
      sessionStorage.setItem("CurrentJZPerson", JSON.stringify(item));
      this.chooseItem = item;
      this.personId = item.id;
      this.showPopup = false;
      this.name = item.name;
      this.idNumber = item.idNumber;
      this.phone = item.phone;
      this.nucleic(sessionStorage.getItem("openid"));
    },
    /**
     * 获取就诊人信息的方法
     */
    getList() {
      let postData = {
        userid: sessionStorage.getItem("openid"),
        type: 1,
      };
      this.$http
        .get("/api/search/cardlist", postData)
        .then((res) => {
          if (res.status === 200) {
            this.loading = false;
            this.list = res.data;
            const CurrentJZPerson = JSON.parse(
              sessionStorage.getItem("CurrentJZPerson")
            );
            if (!CurrentJZPerson) {
              if (this.list.length > 0) {
                this.personId = this.list[0].id;
                for (let i = 0; i < this.list.length; i++) {
                  if (i == 0) {
                    this.chooseItem = this.list[i];
                  }
                  if (this.list[i].deafult) {
                    this.chooseItem = this.list[i];
                  }
                }
                if (!this.chooseItem.deafult) {
                  this.chooseItem.deafult = true;
                  this.list[0].deafult = true;
                }
                sessionStorage.setItem(
                  "CurrentJZPerson",
                  JSON.stringify(this.chooseItem)
                );
              }
            } else {
              this.personId = CurrentJZPerson.id;
              this.chooseItem = CurrentJZPerson;
            }
            this.name = this.chooseItem.name;
            this.idNumber = this.chooseItem.idNumber;
            this.phone = this.chooseItem.phone;
          }
          this.isHasPatient();
          this.getState();
          this.nucleic(sessionStorage.getItem("openid"));
        })
        .catch((err) => {
          this.isHasPatient();
          console.log(err);
        });
    },
    /**
     * 点击对话框确定按钮
     */
    handleConfirm() {
      this.showDialog = false;
      this.$router.push("/nucleicPayment");
    },
    /**
     * 提交调查问卷
     */
    onSubmit() {
      if (!this.isHasPatient()) return;
      if (this.isCheck === "") {
        Notify({
          type: "warning",
          message: "请填写近两周是否出现以下症状选项！",
        });
        return;
      }
      if (this.tourismHistory === "") {
        Notify({ type: "warning", message: "请填写流行病学史第一条！" });
        return;
      }
      if (this.contactHistory === "") {
        Notify({ type: "warning", message: "请填写流行病学史第二条！" });
        return;
      }
      // if (this.contactFeverPatients === "") {
      //   Notify({ type: "warning", message: "请填写流行病学史第三条！" });
      //   return;
      // }
      if (this.aggregationOnset === "") {
        Notify({ type: "warning", message: "请填写流行病学史第三条！" });
        return;
      }
      if (this.riskAreaHistory === "") {
        Notify({ type: "warning", message: "请填写流行病学史第四条！" });
        return;
      }
      // if (this.customsInspection === "") {
      //   Notify({ type: "warning", message: "请填写流行病学史第六条！" });
      //   return;
      // }
      // if (this.transportationPersonnel === "") {
      //   Notify({ type: "warning", message: "请填写流行病学史第七条！" });
      //   return;
      // }
      // if (this.workingPersonnel === "") {
      //   Notify({ type: "warning", message: "请填写流行病学史第八条！" });
      //   return;
      // }
      // if (this.isolatePersonnel === "") {
      //   Notify({ type: "warning", message: "请填写流行病学史第九条！" });
      //   return;
      // }
      if (this.name === "") {
        Notify({ type: "warning", message: "请填写承诺人姓名！" });
        return;
      }
      if (this.idNumber === "") {
        Notify({ type: "warning", message: "请填写身份证号！" });
        return;
      }
      // if (!this.validator(this.idNumber)) {
      //   Notify({ type: "warning", message: "请确认身份证号是否正确！" });
      //   return;
      // }
      if (this.phone === "") {
        Notify({ type: "warning", message: "请填写联系方式！" });
        return;
      }
      // if (!this.phoneValid(this.phone)) {
      //   Notify({ type: "warning", message: "请确认联系电话是否正确！" });
      //   return;
      // }
      if (this.isFever == true) {
        if (this.feverDays == "" || this.feverDays == null) {
          Notify({ type: "warning", message: "请填写发热天数！" });
          return;
        }
        if (this.feverTemperature == "" || this.feverTemperature == null) {
          Notify({ type: "warning", message: "请填写发热温度！" });
          return;
        }
      }
      Dialog.confirm({
        title: "再次确认",
        message: "本人完全了解上述内容并承诺。",
        confirmButtonColor: "#00D48A",
      }).then(() => {
        if (this.riskAreaHistory == true || this.feverTemperature > 37.3) {
          this.redQrCode = true;
        } else {
          this.redQrCode = false;
        }
        let postData = {
          openId: sessionStorage.getItem("openid"),
          name: this.name,
          idNumber: this.idNumber,
          phone: this.phone,
          address: this.address,
          workaddress: this.workUnit,
          a0: this.isCheck,
          a1: this.tourismHistory,
          a2: this.contactHistory,
          // a3: this.contactFeverPatients,
          a4: this.aggregationOnset,
          a5_0: this.riskAreaHistory,
          a5_1_0: this.feverDays,
          a5_1_1: this.feverTemperature,
          a5_2: this.isWeakness || false,
          a5_3: this.isCough || false,
          a5_4: this.isRhinobyon || false,
          a5_5: this.isRunnyNose || false,
          a5_6: this.isSoreThroat || false,
          a5_7: this.isDiarrhea || false,
          a5_8: this.isFeelFade || false,
          a5_9: this.isConjunctivitis || false,
          a5_10: this.isMyalgia || false,
          // a6: this.customsInspection,
          // a7: this.transportationPersonnel,
          // a8: this.workingPersonnel,
          // a9: this.isolatePersonnel,
          type: 2,
        };
        this.$http
          .post("/api/promise/save", postData)
          .then((res) => {
            this.getNucleicJudge();
          })
          .catch((err) => {
            if (err.response.data == "不可重复填写") {
              // 获取当前用户是否有预约信息
              this.getNucleicJudge();
            }
          });
      });
    },
    /**
     * 陪护人员核酸跳转方法
     */
    CaregiversRouterPush() {
      // this.$router.replace({
      //   path: "/infoConfirm",
      //   query: {
      //     addressType: this.$route.query.addressType,
      //     personType: this.$route.query.personType,
      //     collectType: this.$route.query.collectType,
      //     name: this.name,
      //     idNumber: this.idNumber,
      //     phone: this.phone,
      //     inpDept: this.$route.query.inpDept,
      //     inpNo: this.$route.query.inpNo,
      //   },
      // });
    },
    /**
     * 院外人员核酸跳转方法
     */
    OutsideRouterPush() {
      this.$router.replace({
        path: "/infoConfirm",
        query: {
          addressType: 0, //this.$route.query.addressType
          personType: 0, //this.$route.query.personType
          collectType: 0, //this.$route.query.collectType
          name: this.name,
          idNumber: this.idNumber,
          phone: this.phone,
        },
      });
    },
    /**
     * 获取当前用户是否有预约信息
     */
    getNucleicJudge() {
      let param = {};
      param.name = this.name;
      param.idNumber = this.idNumber;
      param.collectType = 0; //this.$route.query.collectType
      param.personType = 0; //this.$route.query.personType
      param.openid = sessionStorage.getItem("openid");
      this.$http.get("/api/nucleic/judge", param).then((result) => {
        if (result.status == 204) {
          // if (this.$route.query.personType == 0) {
          this.OutsideRouterPush();
          // } else if (this.$route.query.personType == 1) {
          //   this.CaregiversRouterPush();
          // }
        } else {
          if (result.data.changeStatus == 0 || result.data.changeStatus == 8) {
            // 已提交未支付
            this.showDialog = true;
            this.tip_notice =
              "您当天已预约河南省第二人民医院本部核酸检测，请到“诊疗服务-核酸缴费（未缴费）”完成支付！";
          } else if (result.data.changeStatus == 1) {
            // 已提交已支付
            this.showDialog = true;
            this.tip_notice =
              "您当天已预约河南省第二人民医院本部核酸检测，可到“诊疗服务-核酸缴费（已缴费）”查看！";
          } else {
            // if (this.$route.query.personType == 0) {
            this.OutsideRouterPush();
            // } else if (this.$route.query.personType == 1) {
            //   this.CaregiversRouterPush();
            // }
          }
        }
      });
    },
    /**
     * 创建核酸检测订单
     */
    nucleic(openid) {
      let postData = {
        openid: openid,
        name: this.name,
        idNumber: this.idNumber,
      };
      this.$http.get("/api/promise/nucleic", postData).then((res) => {
        if (res.data.id != null && res.data.id != "") {
          this.isCheck = res.data.a0;
          this.tourismHistory = res.data.a1;
          this.contactHistory = res.data.a2;
          this.contactFeverPatients = res.data.a3;
          this.aggregationOnset = res.data.a4;
          this.riskAreaHistory = res.data.a5_0;
          this.feverDays = res.data.a5_1_0;
          this.feverTemperature = res.data.a5_1_1;
          this.isWeakness = res.data.a5_2;
          this.isCough = res.data.a5_3;
          this.isRhinobyon = res.data.a5_4;
          this.isRunnyNose = res.data.a5_5;
          this.isSoreThroat = res.data.a5_6;
          this.isDiarrhea = res.data.a5_7;
          this.isFeelFade = res.data.a5_8;
          this.isConjunctivitis = res.data.a5_9;
          this.isMyalgia = res.data.a5_10;
          this.customsInspection = res.data.a6;
          this.transportationPersonnel = res.data.a7;
          this.workingPersonnel = res.data.a8;
          this.isolatePersonnel = res.data.a9;
          this.disabled = true;
        } else {
          this.disabled = false;
          this.isCheck = false;
          this.tourismHistory = "";
          this.contactHistory = "";
          this.contactFeverPatients = "";
          this.aggregationOnset = "";
          this.riskAreaHistory = "";
          this.feverDays = "";
          this.feverTemperature = "";
          this.isWeakness = "";
          this.isCough = "";
          this.isRhinobyon = "";
          this.isRunnyNose = "";
          this.isSoreThroat = "";
          this.isDiarrhea = "";
          this.isFeelFade = "";
          this.isConjunctivitis = "";
          this.isMyalgia = "";
          this.customsInspection = "";
          this.transportationPersonnel = "";
          this.workingPersonnel = "";
          this.isolatePersonnel = "";
        }
      });
    },
    /**
     * 身份证号校验
     */
    validator(val) {
      return /^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/.test(
        val
      );
    },
    /**
     * 手机号验证
     */
    phoneValid(val) {
      return /^(13[0-9]|14[579]|15[0-3,5-9]|16[6]|17[0135678]|18[0-9]|19[89])\d{8}$/.test(
        val
      );
    },
    /**
     * 身份证， 联系电话，承诺人
     */
    getDataList(openid) {
      let postData = {
        openid: openid,
      };
      this.$http.get(requestURL + "/promise/person", postData).then((res) => {
        this.name = res.data.name;
        this.idNumber = res.data.idNumber;
        this.phone = res.data.phone;
      });
    },
  },
  mounted() {
    this.getList();
  },
};
</script>

<style scoped>
.body {
  background-color: #f8f9f9;
}
.activeIcon {
  display: inline-block;
  height: 15px;
  width: 15px;
  border: 1px solid #03d58e;
  border-radius: 50%;
}
.activeIcon:after {
  content: "";
  display: block;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background-color: #03d58e;
  position: relative;
  top: 1px;
  left: 1px;
}
.activeIcon1 {
  display: inline-block;
  height: 15px;
  width: 15px;
  border: 1px solid red;
  border-radius: 50%;
}
.activeIcon1:after {
  content: "";
  display: block;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background-color: red;
  position: relative;
  top: 1px;
  left: 1px;
}
.inactiveIcon {
  display: inline-block;
  height: 15px;
  width: 15px;
  border: 1px solid #a8a5a6;
  border-radius: 50%;
}
.base-info {
  margin: 0.1rem 0.2rem;
}

.title {
  font-size: 0.5rem;
  font-weight: bold;
  display: block;
  padding-top: 25px;
  padding-bottom: 25px;
  /* background-color: red; */
}

.base-info-title,
.checked-info-title {
  font-size: 0.45rem;
  font-weight: bold;
  text-align: left;
}

.checked-info-title {
  margin: 10px auto;
  padding-left: 8px;
}

.base-info-title:before,
.checked-info-title:before {
  content: "";
  display: inline-block;
  width: 5px;
  height: 15px;
  margin: 0px 5px;
  background-color: #00d48a;
  position: relative;
  top: 1px;
  border-radius: 5px;
}

.checked-info-item {
  margin-bottom: 0.5rem;
}

.line {
  width: 100%;
  height: 1px;
  background-color: #d3d3d6;
  padding-left: 0;
}

.checked-info-item-checked {
  width: 9rem;
  margin: auto;
  margin-top: 10px;
  font-size: 0.45rem;
  border: 1px solid #ffffffff;
  border-radius: 10px;
  background-color: #ffffffff;
  padding-left: 15px;
  color: #6f6f6f;
}
.aa {
  display: flex;
  justify-content: space-between;
  align-content: center;
  flex-wrap: wrap;
}
.aa1 {
  flex: 50%;
}
.bb {
  padding-top: 0.2rem;
  width: 9rem;
  margin: auto;
  margin-top: 10px;
  font-size: 0.45rem;
  border: 1px solid #ffffffff;
  border-radius: 10px;
  background-color: #ffffffff;
  padding-left: 15px;
  /* background-color: #00d48a; */
}
.checked-info-item-title {
  font-size: 0.4rem;
  font-weight: bold;
  text-align: left;
  line-height: 25px;
  color: #363636;
}
.checked-info-item-title1 {
  font-size: 0.4rem;
  font-weight: bold;
  text-align: left;
  height: 80px;
  line-height: 25px;
  color: #363636;
}
.checked-info-item-title2 {
  font-size: 0.4rem;
  font-weight: bold;
  text-align: left;
  line-height: 25px;
  color: #363636;
}

.checked-info-item-radio {
  width: 9rem;
  margin: auto;
  font-size: 0.45rem;
  /* background-color: pink; */
  /*border: 1px solid #D3D3D6;*/
  /*border-radius: 5px;*/
}

.checked-info-item-radio .van-radio {
  margin: 0.2rem 0.3rem;
}

.feverDays,
.feverTemperature {
  width: 1rem;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #333333;
}

.checked-info-item-checked .van-checkbox {
  margin: 0.2rem 0;
}

.promise-item {
  width: 9rem;
  margin: auto;
  font-size: 0.4rem;
  text-align: left;
  border: 1px solid #ffffffff;
  border-radius: 10px;
  background-color: #ffffffff;
  padding-left: 10px;
}
.www {
  height: 159px;
  padding-top: 5px;
  line-height: 30px;
}
.www1 {
  margin: 15px 0;
}

.qqq {
  border-radius: 10px;
  background-color: red;
}

.fillDate {
  font-size: 0.5rem;
  float: right;
  margin: 0.2rem;
}

.fillDate input {
  width: 1.2rem;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #333333;
}

/deep/ .van-cell {
  padding: 5px;
}

.submit-btn {
  width: 9.2rem;
  margin: auto;
  border-radius: 5px;
  position: relative;
  border: none;
  background-image: linear-gradient(to right, #57fdc4, #00d48b);
}
.submit-btn-disabled {
  width: 9.2rem;
  margin: auto;
  border-radius: 5px;
  position: relative;
  left: -0.2rem;
  border: none;
  background-color: #afafaf;
}

.type {
  font-size: 0.4rem;
  text-align: left;
}

.type span {
  width: 4.5rem;
  height: 1rem;
  line-height: 1rem;
  display: inline-block;
  text-align: center;
  font-size: 0.5rem;
  font-weight: 800;
  position: relative;
  color: #909795;
}
.typeActive {
  color: #00d48a !important;
}
.typeActive:after {
  content: "";
  display: inline-block;
  width: 20px;
  height: 5px;
  background-color: #00d48a;
  border-radius: 3px;
  position: absolute;
  left: 50%;
  top: 100%;
  transform: translate(-50%, -50%);
}
.bg {
  background-image: url("../../assets/bg.png");
  background-size: 100% 100%;
}
.dialog-message {
  font-size: 0.45rem;
  line-height: 26px;
  margin-bottom: 5px;
  max-height: 40vh;
  overflow: auto;
  text-align: left;
  padding: 0.5rem 0.8rem;
}

.doctor-img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.cancle-btn {
  width: 40%;
  border-radius: 25px;
  margin: 5px;
  margin-bottom: 25px;
  font-size: 0.45rem;
  border: none;
  background-color: #fff;
  background-image: linear-gradient(#d2d2d2, #b9b9b9);
  color: #fff;
}

.confirm-btn {
  width: 40%;
  border-radius: 25px;
  margin: 5px;
  margin-bottom: 25px;
  font-size: 0.45rem;
  border: none;
  background-color: #fff;
  background-image: linear-gradient(#57fdc4, #00d48b);
  color: #fff;
}

.check-box {
  width: 9rem;
  margin: 0 auto;
  text-align: center;
  padding-left: 15px;
}

/deep/ .van-dialog__header {
  padding-bottom: 0.5rem;
}
/deep/ .van-cell-chooseItem {
  padding: 10px 16px;
}
.yyqr-choose-jzk-over {
  display: flex;
  width: 100%;
  align-items: center;
}

.yyqr-choose-title {
  text-align: center;
  font-size: 0.45rem;
  width: 100%;
  padding: 6px;
  color: #373438;
}

.yyqr-choose-jzk {
  padding: 8px;
  font-size: 0.4rem;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  background-color: #faf7fa;
}

.yyqr-choose-jzk .checked {
  color: #03d28c;
}

.yyqr-choose-jzk img {
  height: 0.3rem;
  width: 0.3rem;
}

.yyqr-choose-add {
  padding: 8px;
  width: 100%;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  color: #1ad997;
  box-sizing: border-box;
}

.yyqr-choose-add .van-icon {
  position: relative;
  top: 2px;
  left: -2px;
}

.yyqr-choose-nodata {
  font-size: 0.4rem;
  padding: 8px;
  color: #afafaf;
}
.yyqr-choose-add {
  padding: 8px;
  width: 100%;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  color: #1ad997;
  box-sizing: border-box;
}

.yyqr-choose-add .van-icon {
  position: relative;
  top: -2px;
  left: -2px;
}
/deep/ .van-popup__close-icon--top-right {
  top: 6px;
}
.dialog-title {
  margin-bottom: 0.3rem;
}
</style>
